/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.menu-style {
  background-color: white;
}

.icon-ticsign-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-ticsign {
  height: 32px;
  width: 32px;
}

.menu {
  top: 55px;
}

.drop-zone {
  border: 2px dotted #0782d0;
  border-radius: 30px;
  height: 200px;
  margin: auto;
}

.content-drop-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.content-drop-zone-signature-pad {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.drop-zone-signature-pad {
  border: 2px dotted #0782d0;
  border-radius: 30px;
  height: 80%;
  margin: 10px;
}

.modal-signature-pad {
  --width: 700px !important;
  --height: 450px !important;
}

.modal-signing-file-options{
  --width: 700px !important;
  --height: 450px !important;
}

